@import "src/variables";

.weather-in {
  flex: 1;
  max-width: 592px;

  &__head {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Actay Wide', sans-serif;
    font-weight: 700;

    @include desktop {
      flex-wrap: wrap;
    }

    &-title {
      margin-right: auto;
      font-size: 32px;
      line-height: 40px;

      h3 {
        color: var(--weather-color-title);
        @include desktop {
          font-size: 22px;
          line-height: 28px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--weather-color-subtitle);
      }

      div {
        display: none;

        @include desktop {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: var(--weather-color);
        }
      }
    }

    &-temp {
      font-size: 40px;
      line-height: 51px;
      background: linear-gradient(180deg, #77C4EF 0%, #7A56E0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-right: 16px;

      @include desktop {
        order: 2;
        width: 100%;
      }
    }

    img {

    }
  }

  &__main {
    margin: 35px 0 40px;
    flex-wrap: wrap;
    display: flex;
    gap: 8px;
    @include desktop {
      margin: 12px 0 8px;
    }

    &-card {
      padding: 16px 11px 11px 16px;
      background: var(--weather-card-back);
      border-radius: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--weather-color);
      @include media(1268px) {
        flex: auto;
      }

      div {
        display: flex;
        align-items: center;
        margin-bottom: 1px;
        background: linear-gradient(180deg, #77C4EF 0%, #7A56E0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 700;
        font-size: 24px;
        line-height: 20px;

        svg {
          margin-right: 8px;
        }
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--weather-color);

    &-tabs {
      display: flex;
      background: var(--tab-background);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 14px;
      @include media(678px) {
        width: 100%;
      }
    }

    &-tab {
      cursor: pointer;
      padding: 14px 16px;
      margin: -1px;

      @include media(678px) {
        flex: 1;
        text-align: center;
      }
      @include mobile {
        padding: 14px 6px;
      }

      &_active {
        pointer-events: none;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
        border-radius: 14px;
        background: linear-gradient(92.54deg, #7214FF 2.9%, #923DFF 100.04%);
        color: #FFFFFF;
      }
    }

    span {
      @include desktop {
        display: none;
      }
    }
  }
}