$desktop-xl: 1376px;
$desktop: 1200px;
$tablet-xl: 992px;
$tablet: 768px;
$mobile: 576px;

@mixin media($width) {
  @media screen and (max-width: $width){
    @content;
  }
}
@mixin desktop-xl {
  @media screen and (max-width: $desktop-xl){
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: $desktop){
    @content;
  }
}
@mixin tablet-xl {
  @media screen and (max-width: $tablet-xl){
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tablet){
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: $mobile){
    @content;
  }
}

@mixin purple-light {
  background: #7214FF;
  filter: blur(125px);
}
@mixin green-light {
  background: #41E88D;
  filter: blur(125px);
}