@import "src/variables";
//fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Actay Wide';
  src: url('../public/fonts/ActayWide-Bold.woff2') format('woff2'),
  url('../public/fonts/ActayWide-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//font-family: 'Inter', sans-serif;

//min
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  position: relative;
  background: var(--body);
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.stop-scroll {
  overflow: hidden;

  & .radio-bar {
    margin-right: 16px;
  }
}

main {
  padding: 0 16px;

}

section {
  margin-bottom: 76px;
}

.container {
  max-width: 1344px;
  margin: 0 auto;
}

h1, h2, h3, h4 {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
}

h2 {
  font-size: 40px;
  line-height: 51px;
  background: var(--h2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @include mobile {
    font-size: 22px;
    line-height: 28px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

ul, ol, li {
  list-style: none;
}

label {
  display: block;
}

input {
  width: 100%;
  border: none;
  font: inherit;
}

img {
  display: block;
}

//MAIN
.main__sidebar {
  @include tablet-xl {
    display: none;
  }
}

//basic
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 46px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.button-light {
  position: relative;
  overflow: hidden;
  color: var(--light-color);
  background: var(--button-light);
  border: 1px solid var(--border);

  &:before, &:after {
    position: absolute;
    content: '';
    width: 118px;
    height: 118px;
    opacity: 0;
    transition: left 0.4s ease-in-out, right 0.4s ease-in-out, opacity 0.4s ease-in-out;
  }

  &:before {
    @include purple-light;
    left: 222px;
    bottom: -47px;
  }

  &:after {
    @include green-light;
    right: 222px;
    top: -46px;
  }


  &:hover {
    &:before, &:after {
      opacity: 1;
    }

    &:before {
      left: -40px;
      bottom: -47px;
    }

    &:after {
      right: -41px;
      top: -46px;
    }
  }
}

.button-purple {
  color: #fff;
  background: linear-gradient(92.54deg, #7214FF 2.9%, #923DFF 100.04%);
}
.button-red {
  color: #fff;
  background: linear-gradient(92.54deg, #9e1111 2.9%, #e50505 100.04%);
  width: 170px;
  height: 120px;
  border-radius: 20%, 10%;
}

// TV frame
.video-modal {
  width: 100%;
  max-width: 768px;
}

iframe {
  width: 100%;
  max-height: 508px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 24px;

  @include desktop-xl {
    border-radius: 16px;
  }
}


//Program MODAL
.article__scroll-wrap.program-content {

  .program-content__title-wrap {
    display: flex;
    align-items: center;

    img {
      margin: 0 16px 0 0;
    }
  }

  .program-content__icon {
    max-width: 56px;
    max-height: 56px;
  }

  p {
    color: var(--news-card-paragraph);
  }

  img {
    margin-top: 26px;
    max-width: 100%;
    width: auto;
  }

  h4, h5 {
    color: var(--weather-color-title);
    line-height: 24px;
  }

  h3 {
    margin: 0 0 16px;
  }

  h4 {
    font-weight: 600;
    font-size: 24px;
    margin: 35px 0 24px;
  }

  h5 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 9px;
  }
}

//slider
.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
  height: inherit;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.slick-slide {
  margin: 0 10px;

  & > div {
    width: 100%;
    display: flex;
  }
}

@keyframes pulse {
  0% {
    width: 39px;
    height: 39px;
  }
  50% {
    width: 64px;
    height: 64px;
  }
  100% {
    width: 39px;
    height: 39px;
  }
}


//theme
html {
  --body: linear-gradient(0deg, #D7DBEE, #D7DBEE), #060B27;
  --header-nav: #737B8F;
  --header-nav-active: #0F1430;
  --button-light: #E2E7FF;
  --light-color: #232029;
  --border: #ffffff;
  --burger-button: rgba(0, 0, 0, 0.1);
  --h2: linear-gradient(180deg, #35354D 0%, rgba(53, 53, 77, 0.78) 100%);
  --card-background: #E2E7FF;
  --card-img-background: rgba(0, 0, 0, 0.05);
  --tab-background: rgba(255, 255, 255, 0.35);
  --weather-color: #51496D;
  --weather-color-title: #35354D;
  --weather-color-subtitle: #35354D;
  --weather-color-card: #51496D;
  --weather-card-back: rgba(226, 231, 255, 0.6);
  --currency-back: rgba(226, 231, 255, 0.6);
  --news-card-paragraph: #4D515B;
  --news-card-date: #3F3F49;
  --breadcrumbs: #737B8F;
  --article-date: rgba(63, 63, 73, 0.5);

}

.dark {
  --body: #060B27;
  --header-nav: #8F9BB7;
  --header-nav-active: #F5F5F5;
  --button-light: linear-gradient(261.47deg, rgba(21, 25, 52, 0.52) 14.6%, rgba(21, 25, 52, 0.08) 49.66%, rgba(21, 25, 52, 0.49) 87.77%);
  --light-color: #ffffff;
  --border: #282D45;
  --burger-button: rgba(255, 255, 255, 0.1);
  --h2: linear-gradient(180deg, #F6F6F7 27.3%, #7E808F 70.49%);
  --card-background: #0E1330;
  --card-img-background: rgba(255, 255, 255, 0.05);
  --tab-background: rgba(0, 0, 0, 0.35);
  --weather-color: #C7D1E9;
  --weather-color-title: #ffffff;
  --weather-color-subtitle: #C7D1E9;
  --weather-color-card: #ffffff;
  --weather-card-back: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(125, 33, 255, 0.25) 100%);
  --currency-back: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(125, 33, 255, 0.3) 100%);
  --news-card-paragraph: #C7D1E9;
  --news-card-date: #F6F6F7;
  --breadcrumbs: rgba(246, 246, 247, 0.15);
  --article-date: #8F9BB7;
}
