@import "src/variables";

.frequencies {
  width: 100%;
  margin-bottom: 112px;

  h1 {
    font-size: 40px;
    line-height: 51px;
    background: var(--h2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 34px;

    @include mobile {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__map-wrap {
    border-radius: 32px;
    overflow: hidden;
    height: 576px;
  }
}
