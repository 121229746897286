@import "src/variables";

.horizontal-banner {
  margin: 8px -10px;
  border-radius: 24px;
  overflow: hidden;

  img {
    border: 1px solid #282D45;
    border-radius: 24px;
    width: 100%;

    @include mobile {
      margin: 24px 0;
    }
  }
}