
.breadcrumbs {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--weather-color-title);
  margin-bottom: 22px;

  a {
    color: var(--breadcrumbs);
  }
}