@import "src/variables";

.news {
  flex: 1;
  margin-bottom: 228px;

  @include mobile {
    margin-bottom: 124px;
  }

  h1 {
    font-size: 40px;
    line-height: 51px;
    background: var(--h2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__grid {
    margin-top: 33px;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    @include media(1125px) {
      grid-template-columns: repeat(1, 1fr);
    }

    & > div {

      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 3;
        border-radius: 24px;
        overflow: hidden;

        @include media(1125px) {
          grid-column-end: 2;
        }
      }
    }
  }
}