@import "src/variables";

.header {
  padding: 43px 16px 46px;

  &__inner {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    & > a {
      position: relative;
      z-index: 100;
      display: flex;
      align-items: center;
      max-height: 44px;

      img {
        max-width: 120px;
        max-height: 102px;
      }
    }
  }
  .menu-banner {
    width: 50%; /* Занимает всю ширину контейнера */
    max-width: 1200px; /* Чтобы размеры картинки соответствовали размерам меню */
    height: auto; /* Для сохранения пропорций картинки */
    display: block; /* Чтобы картинка не была inline элементом */
    margin: 0 auto; /* Центрирование картинки */
    position: relative;
    margin-top: 3%;
    border-radius: 5%;
    z-index: -1;
    @include mobile{
      margin-top: 7%;

    }
  }
  .runningline{
    display:flexbox;
    // border: 1px solid purple;
    max-width: 100%;
    margin-top: 3%;
    @include mobile{
      margin-top: 7%;

    }

  }
  .radioshorts{
    position: absolute;
    top: 19,5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    align-items: center;
    margin-top: 3%;
    justify-content: center;
    border-radius: 50%;
    width: 48px; /* установите нужную ширину */
    height: 48px; /* установите нужную высоту */
    @include mobile{
      margin-top: 15%;
      margin-left: 2%;

    }

  }
  &__nav {
    max-width: 569px;
    flex: 1;

    @include media(1023px) {
      display: none;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-item {
      font-family: 'Actay Wide', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
    }

    &-link {
      color: var(--header-nav);

      &_active {
        color: var(--header-nav-active);
      }
    }
  }

  &__buttons-wrap {
    display: flex;
    gap: 8px;
    align-items: center;

    @include media(1023px) {
      margin-left: auto;
    }

    & > a {
      @include media(1350px) {
        display: none;
      }
    }
  }

  &__button {
    padding: 15px 23px;

    @include media(1023px) {
      display: none;
    }
  }

  &__theme-button {
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 2px;
    background: linear-gradient(92.54deg, #7214FF 2.9%, #923DFF 100.04%);
    border-radius: 50%;
    position: relative;
    z-index: 100;

    @include media(1023px) {
      border-radius: 8px;
      background: var(--burger-button);
    }


    &-inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(0deg, #D7DBEE, #D7DBEE), #060B27;

      @include media(1023px) {
        background: transparent;
      }

      &_white {
        background: linear-gradient(92.54deg, #7214FF 2.9%, #923DFF 100.04%);

        @include media(1023px) {
          background: transparent;
        }

        svg {
          path {
            &:not(:first-child) {
              fill: #fff;
            }

            &:first-child {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}