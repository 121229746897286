.footer-list {
  position: relative;

  h4 {
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 23px;
  }

  &__inner-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
  }
}

