@import "src/variables";

.socials {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-around;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #060B27;
    border: 1px solid #282D45;
    border-radius: 8px;
  }
}