@import "src/variables";

.main {
  width: calc(100% - 336px);
  max-width: 1008px;
  margin-bottom: 76px;

  @include tablet-xl {
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 32px;

    main {
      flex: 1;
    }
  }

  &__mobile-sidebar {
    display: none;

    @include tablet-xl {
      display: block;
      margin-bottom: 24px;
      & > div {
        flex-direction: row;
        justify-content: center;
      }

      img {
        max-width: 304px;
        width: calc(50% - 8px);
      }
    }
  }
}