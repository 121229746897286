@import "src/variables";

.authors-program {

  @include mobile {
    margin-bottom: 24px;
  }

  &__head {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 16px;
    }

    &-button {
      padding: 15px 26px;
      min-width: 178px;

      @include mobile {
        display: none !important;
      }

      &_mobile.button-light {
        display: none;

        @include mobile {
          margin-top: 16px;
          text-align: center;
          padding: 15px 26px;
          display: block;
        }
      }
    }
  }

  &__list {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    @include media(1100px) {
      flex-wrap: wrap;

      & > div {
        min-width: calc(50% - 8px);
      }
    }
  }
}