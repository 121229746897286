@import "src/variables.scss";

.running-line {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--block-background);
  border: 1px solid var(--block-border);
  border-radius: 28px;
  overflow: hidden;
  min-height: 56px;
  isolation: isolate;
  z-index: 2;

  &__warning-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 28px;
    overflow: hidden;

    &:before, &:after {
      position: absolute;
      content: '';
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      background: url("../../../../icons/running-line-underline.svg");
      height: 4px;
      width: 100%;
      z-index: 0;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }


  @include desktop-xl {
    min-height: 38px;
  }

  &__track {
    width: 100%;
  }

  &__inner-wrapper {
    position: absolute;
    display: flex;
    gap: 32px;
    flex-wrap: nowrap;
    width: max-content;

  }
}

.text-block {
  padding: 18px 0 17px;
  width: max-content;
  white-space: nowrap;
  color: var(--semi-black-text);

  @include desktop-xl {
    padding: 0;
  }

  

  &__notice {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFEFF;
    background: #EE5259;
    border-radius: 96px;
    padding: 6px 13px;
    margin-right: 16px;
    max-height: 100%;
  }
}
