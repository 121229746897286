@import "src/variables";

.footer {
  position: relative;
  background-color: #060B27;
  padding: 89px 16px 40px;
  color: #C7D1E9;
  font-weight: 400;


  &:before {
    position: absolute;
    z-index: 0;
    content: '';
    left: calc(50% - 242px / 2);
    top: calc(50% - 242px / 2);
    width: 242px;
    height: 242px;
    background: #7214FF;
    filter: blur(250px);
  }

  //top
  &-main {
    display: flex;
    align-items: flex-start;
    padding-bottom: 55px;

    @include media(1100px) {
      flex-direction: column;
    }

    img {
      max-width: 140px;
      margin-right: 101px;
      margin-bottom: 36px;
      @include media(1100px) {
        margin: 0 auto 36px auto;
      }
    }

    &__lists {
      flex: 1;
      width: 100%;
      display: flex;

      justify-content: space-between;

      @include media(860px) {
        flex-wrap: wrap;
        gap: 48px;

        & > div:last-of-type {
          width: 100%;
        }
      }

      @include mobile {
        flex-direction: column;
      }
    }

  }


  
  &__socials-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid rgba(217, 217, 217, 0.1);
    padding-top: 28px;

    @include mobile {
      flex-direction: column;
    }

    a {
      color: #F6F6F7;
    }
  }

  &__socials {
    justify-content: flex-end;
    margin-left: 36px;

    @include mobile {
      margin-top: 36px;
      margin-left: 0;
    }
  }
}

.one{
  position: relative;
  color: #ed6161;
  text-decoration: underline;

}

.one:hover .submenu{
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.one:after{
  position:absolute;
  content: '';
  left: -5%;
  top: 20%;
  transform: translateY();
  border: 5px solid transparent;
  border-top-color: #089;
}

.submenu{
  bottom: -90%;
  font-size:small;
  color: #C7D1E9;
  text-decoration:underline;
  position: absolute;
  transform: translateX();
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease;

}
