@import "src/variables";

.team {
  position: relative;
  z-index: 1;
  flex: 1;
  padding-bottom: 120px;

  h1 {
    font-size: 40px;
    line-height: 51px;
    background: var(--h2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 15px;
  }

  &__slide {
    border-radius: 24px;
    color: #FFFFFF;

    &-inner {
      border-radius: 0 0 24px 24px;
      background-color: #ffffff2b;
      position: relative;
      padding: 24px 0 24px 24px;

      &:before {
        position: absolute;
        content: '';
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 24px;
        background-color: #ffffff2b;
        z-index: -1;
      }

      div {
        margin-bottom: 6px;
        font-family: 'Actay Wide', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #F6F6F7;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  &__main {
    display: flex;
    gap: 36px;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
      align-items: center;
    }

    &-text {
      color: var(--news-card-paragraph);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      max-width: 656px;
    }

    &-slider {
      position: relative;
      width: 336px;

      //lights
      &:before, &:after, .team__main-slider-middle-light {
        position: absolute;
        z-index: -1;
        content: '';
        width: 104.12px;
        height: 222.85px;
        top: 171px;
        filter: blur(75px);
        border-radius: 24px;
      }

      .team__main-slider-middle-light {
        background: #A8E9FF;
        right: 122px;
      }

      &:before {
        background: #C6FFE0;
        right: 20px;
      }

      &:after {
        background: #A64CE8;
        left: 20px;
      }

      img {
        width: 100%;
        border-radius: 24px;
      }

      &-arrows {
        gap: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
      }

      &-arrow {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background: #0E1330;
        border: 1px solid #282D45;
        border-radius: 8px;
      }

      .slick-list {
        border-radius: 24px;
      }
    }
  }
}