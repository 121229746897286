@import "src/variables";

.news-card {
  position: relative;
  overflow: hidden;
  font-family: 'Actay Wide', sans-serif;
  background: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 24px;
  padding: 40px 21px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @include mobile {
    padding: 18px 8px 20px 16px;
  }

  &:before, &:after {
    position: absolute;
    content: '';
    width: 118px;
    height: 118px;
    opacity: 0;
    transition: left 0.4s ease-in-out, right 0.4s ease-in-out, opacity 0.4s ease-in-out;
  }

  &:before {
    @include purple-light;
    left: 222px;
    bottom: -47px;
  }

  &:after {
    @include green-light;
    right: 222px;
    top: -46px;
  }

  &:hover {
    &:before, &:after {
      opacity: 1;
    }

    &:before {
      left: 32px;
      bottom: -66px;
    }

    &:after {
      right: 72px;
      top: -62px;
    }
  }

  &__info {

    h4 {
      color: var(--weather-color-title);
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }

    p {
      margin: 6px 0 44px;
      color: var(--news-card-paragraph);
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        font-size: 12px;
        line-height: 16px;
        margin: 4px 0 26px;
      }
    }
  }

  &__link-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      cursor: pointer;
      display: block;
      position: relative;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      text-transform: uppercase;

      background: linear-gradient(97.3deg, #7214FF 1.94%, #923DFF 99.51%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @include mobile {
        font-size: 11px;
        line-height: 14px;
      }

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        background: linear-gradient(97.3deg, #7214FF 1.94%, #923DFF 99.51%);
        height: 1px;
        bottom: 0;
        left: 0;
      }
    }

    span {
      color: var(--news-card-date);
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      @include mobile {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}