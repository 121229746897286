.team {
  position: relative;
  z-index: 1;
  flex: 1;
  padding-bottom: 1000px;
}

.team h1 {
  font-size: 40px;
  line-height: 51px;
  background: var(--h2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.team__slide {
  border-radius: 24px;
  color: #FFFFFF;
}

.team__slide-inner {
  border-radius: 0 0 24px 24px;
  background-color: #ffffff2b;
  position: relative;
  padding: 24px 0 24px 24px;
}

.team__slide-inner:before {
  position: absolute;
  content: '';
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 10px; /* Changed height to 10px for thinner stripes */
  background-image: repeating-linear-gradient(to right, #ffffff2b, #ffffff2b 10px, transparent 10px, transparent 20px); /* Added background image for stripes */
  z-index: -1;
}

.team__slide-inner div {
  margin-bottom: 6px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #F6F6F7;
}

.team__slide-inner span {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.team__main {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.team__main-text {
  color: var(--news-card-paragraph);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 656px;
}

.team__main-slider {
  position: relative;
  width: 336px;
}

.team__main-slider:before,
.team__main-slider:after,
.team__main-slider .team__main-slider-middle-light {
  position: absolute;
  z-index: -1;
  content: '';
  width: 104.12px;
  height: 222.85px;
  top: 171px;
  filter: blur(75px);
  border-radius: 24px;
}

.team__main-slider-middle-light {
  background: #A8E9FF;
  right: 122px;
}

.team__main-slider:before {
  background: #C6FFE0;
  right: 20px;
}

.team__main-slider:after {
  background: #A64CE8;
  left: 20px;
}

.team__main-slider img {
  width: 100%;
  border-radius: 24px;
}

.team__main-slider-arrows {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.team__main-slider-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: #0E1330;
  border: 1px solid #282D45;
  border-radius: 8px;
}

.slick-list {
  border-radius: 24px;
}

.file {
  margin: 10px;
}

.panelmenu {
  background: linear-gradient(92.54deg, #7214FF 2.9%, #923DFF 100.04%);
  border-radius: 50px;
  color: #ffffff;
  display: flex;
  height: 25%;
  padding-left: 30px;
  padding-right: 60px;
  padding-bottom: 10px;
  margin: 10px 0;
}

.btnDownload {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 300;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  font-family: "Actay Wide", sans-serif;
}

.namemenu {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Actay Wide", sans-serif;
}
