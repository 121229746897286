@import "src/variables";

.programs {
  flex: 1;
  margin-bottom: 76px;

  h2 {
    margin-bottom: 34px;
    @include mobile {
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-top: 75px;
      @include mobile {
        margin-top: 44px;
      }
    }
  }

  &__list {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include media(1025px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}