@import "src/variables";

.last-news {
  margin-bottom: 0;

  &__head {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__grid {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include media(1110px) {
      grid-template-columns: repeat(1, 1fr);
    }

    & > div {

      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 3;
        border-radius: 24px;
        overflow: hidden;

        @include media(1125px) {
          grid-column-end: 2;
        }
      }
    }
  }

  &__button-mobile.button-light {
    display: none;

    @include mobile {
      display: flex;
      padding: 15px;
      margin-top: 16px;
    }
  }
}