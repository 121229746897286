@import "src/variables";

.main-slider {

  &__banner-inner_wrap {
    img {
      width: 100%;
      border: 1px solid var(--border);
      border-radius: 24px;
    }
  }

  .slick-slider {
    position: relative;
  }

  .slick-list {
    border-radius: 24px;
  }

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    width: 56px;
    height: 56px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    background: var(--card-background);
    border: 1px solid var(--border);
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-next {
    right: 32px;
  }

  .slick-prev {
    left: 32px;
  }

  &__custom-dots {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 1;
    display: flex;
    gap: 16px;
    align-items: center;

    @include tablet {
      bottom: -28px;
    }

    li {
      cursor: pointer;
      width: 10px;
      height: 10px;
      background: #FFFFFF;
      border-radius: 50%;

      &.slick-active {
        pointer-events: none;
        width: 16px;
        height: 16px;
        background: #7214FF;
      }
    }
  }

  &__custom-dot {
    width: 100%;
    height: 100%;
  }
}