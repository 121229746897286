@import "src/variables";

.article {
  position: relative;
  overflow: hidden;
  background: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 24px;
  padding: 32px 21px;
  flex: 1;
  font-family: 'Actay Wide', sans-serif;
  max-width: 640px;
  min-width: 340px;

  &:before, &:after {
    position: absolute;
    content: '';
    width: 118px;
    height: 118px;
  }

  &:before {
    @include purple-light;
    left: 32px;
    bottom: -66px;
  }

  &:after {
    @include green-light;
    right: 72px;
    top: -62px;
  }

  &__inner {

    & > span {
      color: var(--article-date);
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    h3 {
      margin: 37px 0 16px;
      color: var(--weather-color-title);
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    img {
      width: 100%;
      border-radius: 24px;
    }
  }

  &__scroll-wrap {
    overflow-y: auto;
    max-height: 784px;
    padding-right: 4px;

    color: var(--news-card-paragraph);
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;


    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--breadcrumbs);
      border-radius: 30px;
    }

    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
      border-radius: 24px;
    }

    p {
      &:not(:last-child) {
      margin-bottom: 18px;

      }
    }
  }

  &__content {
    margin: 21px 0 50px;
  }

  &__buttons {
    display: flex;
    column-gap: 32px;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    & > div {
      flex: 1;
      padding: 19px;
    }
  }
}
