@import "src/variables";

.weather-section {

  @include mobile {
    margin-bottom: 44px;
  }

  &__inner {
    h2 {
      margin-bottom: 32px;
      @include mobile {
        display: none;
      }
    }

    &-wrap {
      position: relative;
      padding: 16px 16px 16px 32px;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      border-radius: 24px;
      overflow: hidden;

      @include desktop {
        padding: 16px;
      }
      @include media(678px) {
        flex-direction: column;
        gap: 24px;
      }

      & > img {
        z-index: -1;
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        height: 100%;
        //object-fit: cover;
        mix-blend-mode: overlay;

      }
    }
  }

}