.radio-player {
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #FF0000; /* Красный цвет фона */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.radio-button:hover {
    background-color: #E00000; /* Темнее красный при наведении */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
}

.radio-button svg {
    fill: #FFFFFF; /* Белый цвет иконки */
    width: 30px;
    height: 30px;
}

@keyframes rain {
    0% { transform: translateY(-20px); opacity: 1; }
    100% { transform: translateY(100vh); opacity: 0; }
}

.emoji {
    position: absolute;
    font-size: 24px;
    animation: rain 10s linear forwards;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
    .radio-button {
        width: 74px;
        height: 74px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .radio-button svg {
        width: 35px;
        height: 35px;
    }

    .emoji {
        font-size: 40px; /* Меньший размер для мобильных устройств */
    }
}

.volume-slider {
    width: 100px; /* Ширина ползунка */
    cursor: pointer;
    margin: 10px;
}

.radio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Элементы теперь будут расположены вертикально */
}

.radio-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #FF0000;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    margin-bottom: 10px; /* Отступ от кнопки до ползунка громкости */
}

.radio-button:hover {
    background-color: #E00000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
}

