@import "src/variables";

.currency {
  padding: 27px 16px 16px 16px;
  background: var(--currency-back);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;

  color: var(--weather-color-title);
  width: 320px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  @include media(678px) {
    width: 100%;
  }

  h4 {
    font-family: 'Actay Wide', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }

  &__number {
    font-family: 'Actay Wide', sans-serif;
    text-align: center;
    margin: 45px 0 42px;

    div {
      font-weight: 700;
      font-size: 46.8837px;
      line-height: 59px;
      text-align: center;
      background: linear-gradient(92.54deg, #7214FF 2.9%, #923DFF 100.04%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .weather-in__nav-tabs {
    width: 100%;
    justify-content: space-between;
  }

  .weather-in__nav-tab {
    flex: 1;
    text-align: center;
    padding: 14px 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}