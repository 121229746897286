@import "src/variables";

.sidebar {
  &__inner {
    gap: 16px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 16px;
    margin-bottom: 76px;
    @include tablet-xl {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 100%;
    }

    img {
      max-width: 304px;
      border: 1px solid var(--border);
      border-radius: 24px;
      margin: 0 auto;
      @include tablet-xl {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
      }
  
      
      
    }
  }

  &__socials {
    @include tablet-xl {
      display: none !important;
      size: 126px;
    }
  }
}