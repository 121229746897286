@import "src/variables";

.program-card {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 24px;
  padding: 40px 21px 36px;
  flex: 1;

  @include mobile {
    padding: 16px;
    border-radius: 12px;
  }

  &:before, &:after {
    position: absolute;
    content: '';
    width: 118px;
    height: 118px;
    opacity: 0;
    transition: left 0.4s ease-in-out, right 0.4s ease-in-out, opacity 0.4s ease-in-out;
  }

  &:before {
    @include purple-light;
    left: 222px;
    bottom: -47px;
  }

  &:after {
    @include green-light;
    right: 222px;
    top: -46px;
  }

  &:hover {
    &:before, &:after {
      opacity: 1;
    }

    &:before {
      left: -40px;
      bottom: -47px;
    }

    &:after {
      right: -41px;
      top: -46px;
    }
  }

  img {
    max-width: 50%;
    width: 104px;
    height: 104px;
    margin: 0 auto 24px auto;

    @include mobile {
      margin-bottom: 14px;
    }
  }

  h4 {
    max-width: 117px;
    margin: 0 auto;
    font-family: 'Actay Wide', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--light-color);

    @include mobile {
      font-size: 12px;
      line-height: 15px;
    }
  }
}