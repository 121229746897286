
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;

  &__inner {
    position: relative;
    max-height: 100%;
  }

  &__closeButton {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 44px;
    width: 40px;
    height: 40px;
    background-color: #7214FF;
    z-index: 1;
    border-radius: 50%;

    div {
      height: inherit;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        position: absolute;
        content: '';
        background-color: rgba(114, 20, 255, 0.1);
        width: 64px;
        height: 64px;
        border-radius: 50%;
        animation: pulse 1.5s infinite linear;
      }
    }

    svg {

    }
  }
}
