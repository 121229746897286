@import "src/variables";

.burger-menu {
  display: none;

  @include media(1023px) {
    display: block;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 48px;
    background: var(--burger-button);
    border-radius: 8px;
    position: relative;
    z-index: 100;
  }

  &__mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);

    &_visible {
      display: block;
    }
  }

  &__inner-wrap {
    background: linear-gradient(119.66deg, #7214FF -1.53%, #923DFF 79.49%);
    font-family: 'Actay Wide', sans-serif;
    font-weight: 700;
    padding-top: 130px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 32px;
    padding: 0 32px;
  }

  &__item {
    cursor: pointer;
    color: #DBE6F8;
    font-size: 24px;
    line-height: 30px;
  }

  &__socials {
    justify-content: flex-start;
    margin: 0 0 24px 32px;
  }

  &__dropdown-button {
    display: block;
    cursor: pointer;
    padding: 26px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;

    &_call {
      background-color: rgba(6, 11, 39, 0.25);
    }

    &:last-child {
      background: #060B27;
    }
  }
}
